//
// Custom Styles
//

// Custom
@import "custom/custom";

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  main {
    flex: 1 0 auto;
  }
}