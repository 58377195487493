/* Add here all your css styles (customizations) */

// Variables
@import "custom.variables";

// Mixins
@import "custom.mixins";

//Navigation
.navbar-brand {
  img { max-height: 35px; }
}